<template lang="html">
  <BsCheckbox
    v-model="modelProxy" class="checkbox"
    inline tristate
  />
</template>

<script>
import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    BsCheckbox
  },
  props: {
    value: String
  },
  computed: {
    modelProxy: {
      get () {
        return this.value === 'enabled'
          ? true
          : this.value === 'disabled'
            ? false
            : null
      },
      set (value) {
        const state = value === true
          ? 'enabled'
          : value === false
            ? 'disabled'
            : 'roleRequired'

        this.$emit('input', state)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  margin: 0;
  padding: 0;

  ::v-deep input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
}
</style>
