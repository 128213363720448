<template lang="html">
  <SimpleTable :columns="columns" :data="features">
    <template #header-name>
      <IxRes>administration.portalFeatures.featureName</IxRes>
    </template>
    <template #header-state>
      <IxRes>administration.portalFeatures.featureStatus</IxRes>
    </template>
    <template #header-remove>
      <IxRes>administration.portalFeatures.featureDelete</IxRes>
    </template>

    <template #name="{value, row: {experimental}}">
      <span :title="$i18n.translate('administration.portalFeatures.hints.experimental')">
        <FontAwesomeIcon v-if="experimental" :class="$style.experimental" :icon="icons.experimental" />
      </span>
      <span>{{ value }}</span>
    </template>
    <template #state="{value, row: {name}}">
      <FeatureStateCheckbox
        :value="value"
        @input="$emit('update-state', {name, newState: $event})"
      />
    </template>
    <template #remove="{row}">
      <FontAwesomeIcon :class="$style.remove" :icon="icons.remove" @click="$emit('remove', row)" />
    </template>
  </SimpleTable>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faVial, faBan} from '@fortawesome/free-solid-svg-icons'

import SimpleTable from '@components/table/SimpleTable'

import FeatureStateCheckbox from './FeatureStateCheckbox'

export default {
  components: {
    SimpleTable,
    FeatureStateCheckbox,
    FontAwesomeIcon
  },
  props: {
    features: Array
  },
  computed: {
    columns () {
      return {
        name: {},
        state: {},
        remove: {}
      }
    },
    icons () {
      return {
        experimental: faVial,
        remove: faBan
      }
    }
  }
}
</script>

<style lang="scss" module>
.experimental {
  color: #00b3ee;
  margin-right: 0.5em;
  cursor: help;
}

.remove {
  font-size: 1.2em;
  color: darkred;
  margin: 0;
  margin-top: 3px;

  &:hover {
    cursor: pointer;
    color: red;
  }
}
</style>
