<template lang="html">
  <div class="new-feature-form">
    <TextInput
      ref="name"
      v-model="name" name="name"
      class="name" placeholder="Feature Name"
      required :rule="nameRule"
    >
      <template #label>
        <IxRes>administration.portalFeatures.addNewFeatureName</IxRes>
      </template>
    </TextInput>

    <SimpleSwitch v-model="experimental" class="switch">
      <IxRes>administration.portalFeatures.addNewFeatureExperimental</IxRes>
    </SimpleSwitch>

    <IxButton large save @click="tryToSave">
      <IxRes>administration.portalFeatures.buttons.addNewFeature</IxRes>
    </IxButton>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import SimpleSwitch from '@components/forms/SimpleSwitch'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    TextInput,
    IxButton,
    SimpleSwitch
  },
  mixins: [
    FormPartMixin
  ],
  computed: {
    ...mapFormFields('administration/portalFeatures', [
      'experimental',
      'name'
    ]),
    ...mapState('administration/portalFeatures', [
      'features'
    ])
  },
  methods: {
    nameRule (name) {
      if (this.features.find(feature => feature.name === name)) {
        return this.$i18n.translate('administration.portalFeatures.validation.duplicateName')
      }

      return true
    },
    async tryToSave () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      this.$emit('save')
    }
  },
  watch: {
    name (name) {
      // prevent error validation after saving (when state resets)
      if (name === null) {
        this.$refs.name.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-feature-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > .name {
    width: 40%;
  }

  > .switch {
    margin-top: 1em;
    margin-bottom: 2em;
  }
}
</style>
