<template lang="html">
  <div :class="$style.legend">
    <h5> <IxRes>administration.portalFeatures.legend.title</IxRes> </h5>
    <BsCheckbox
      :class="$style.checkbox" inline
      tristate
      :state="true" disabled
    >
      <IxRes>administration.portalFeatures.legend.portalwide</IxRes>
    </BsCheckbox>
    <BsCheckbox
      :class="$style.checkbox" inline
      tristate
      :state="null" disabled
    >
      <IxRes>administration.portalFeatures.legend.requiresRole</IxRes>
    </BsCheckbox>
    <BsCheckbox
      :class="$style.checkbox" inline
      tristate
      :state="false" disabled
    >
      <IxRes>administration.portalFeatures.legend.disabled</IxRes>
    </BsCheckbox>
  </div>
</template>

<script>
import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    BsCheckbox
  }
}
</script>

<style lang="scss" module>
.legend {
  margin: 26px;
  border: 1px solid #cacaca;
  padding-left: 7px;
  padding-bottom: 7px;
}
.checkbox {
  margin: 0;
  padding: 0;
}
.checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
</style>
