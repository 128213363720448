<template>
  <AdministrationLayout>
    <template #header>
      <IxRes :context="{portal: portalId}">administration.portalFeatures.header</IxRes>
    </template>

    <div class="portal-features">
      <div class="feature-container">
        <label><IxRes>administration.portalFeatures.featureList</IxRes></label>
        <hr>
        <PortalFeatureTable
          :features="features"
          @update-state="updateState"
          @remove="showDeleteModal"
        />
        <br>

        <label><IxRes>administration.portalFeatures.addNewFeature</IxRes></label>
        <hr>

        <PortalFeatureForm @save="create" />
      </div>

      <FeatureStateLegend class="legend" />

      <FeatureRemoveConfirmationModal
        v-model="showRemoveConfirmationModal"
        :feature="selectedFeature"
        @confirm="confirmRemove"
        @cancel="cancelRemove"
      />

      <FrsLoadingIndicator :requests="['admin.portalFeatures.load']" />
    </div>
  </AdministrationLayout>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {notifications, config} from 'src/js/infrastructure'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import AdministrationLayout from './components/AdministrationLayout'
import PortalFeatureTable from './components/portal-features/PortalFeatureTable'
import PortalFeatureForm from './components/portal-features/PortalFeatureForm'
import FeatureStateLegend from './components/portal-features/FeatureStateLegend'
import FeatureRemoveConfirmationModal from './components/portal-features/FeatureRemoveConfirmationModal'

export default {
  components: {
    AdministrationLayout,
    PortalFeatureTable,
    PortalFeatureForm,
    FeatureStateLegend,
    FeatureRemoveConfirmationModal,
    FrsLoadingIndicator
  },
  data () {
    return {
      showRemoveConfirmationModal: false,
      selectedFeature: null
    }
  },
  computed: {
    ...mapState('administration/portalFeatures', [
      'features'
    ]),
    portalId () {
      return config.portalId
    }
  },
  methods: {
    ...mapActions('administration/portalFeatures', [
      'createFeature',
      'getFeatures',
      'setFeatureState',
      'removeFeature'
    ]),
    showDeleteModal (feature) {
      this.selectedFeature = feature
      this.showRemoveConfirmationModal = true
    },
    async create () {
      try {
        await this.createFeature()
        notifications.success(this.$i18n.translate('administration.portalFeatures.notifications.saveSuccess'))
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.portalFeatures.notifications.saveError'))
      }
    },
    async updateState ({name, newState}) {
      try {
        await this.setFeatureState({name, newState})
        notifications.success(this.$i18n.translate('administration.portalFeatures.notifications.stateChangeSuccess'))
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.portalFeatures.notifications.stateChangeError'))
      }
    },
    cancelRemove () {
      this.selectedFeature = null
    },
    async confirmRemove () {
      try {
        await this.removeFeature(this.selectedFeature)
        notifications.success(this.$i18n.translate('administration.portalFeatures.notifications.removeSuccess'))
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.portalFeatures.notifications.removeError'))
      }

      this.selectedFeature = null
    }
  },
  created () {
    this.getFeatures()
  }
}
</script>

<style lang="scss" scoped>
.portal-features {
  display: flex;
  align-items: flex-start;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cacaca;
  margin: 1px 0 4px 0;
  padding: 0;
}

.feature-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  grid-area: table;
}
</style>
