import { render, staticRenderFns } from "./FeatureRemoveConfirmationModal.vue?vue&type=template&id=a71d1496&scoped=true&lang=html&"
import script from "./FeatureRemoveConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./FeatureRemoveConfirmationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a71d1496",
  null
  
)

export default component.exports