var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleTable',{attrs:{"columns":_vm.columns,"data":_vm.features},scopedSlots:_vm._u([{key:"header-name",fn:function(){return [_c('IxRes',[_vm._v("administration.portalFeatures.featureName")])]},proxy:true},{key:"header-state",fn:function(){return [_c('IxRes',[_vm._v("administration.portalFeatures.featureStatus")])]},proxy:true},{key:"header-remove",fn:function(){return [_c('IxRes',[_vm._v("administration.portalFeatures.featureDelete")])]},proxy:true},{key:"name",fn:function(ref){
var value = ref.value;
var experimental = ref.row.experimental;
return [_c('span',{attrs:{"title":_vm.$i18n.translate('administration.portalFeatures.hints.experimental')}},[(experimental)?_c('FontAwesomeIcon',{class:_vm.$style.experimental,attrs:{"icon":_vm.icons.experimental}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(value))])]}},{key:"state",fn:function(ref){
var value = ref.value;
var name = ref.row.name;
return [_c('FeatureStateCheckbox',{attrs:{"value":value},on:{"input":function($event){return _vm.$emit('update-state', {name: name, newState: $event})}}})]}},{key:"remove",fn:function(ref){
var row = ref.row;
return [_c('FontAwesomeIcon',{class:_vm.$style.remove,attrs:{"icon":_vm.icons.remove},on:{"click":function($event){return _vm.$emit('remove', row)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }