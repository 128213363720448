<template lang="html">
  <ConfirmationModal v-model="modelProxy" v-on="$listeners">
    <p slot="title">
      <IxRes>administration.portalFeatures.deleteFeatureTitle</IxRes>
    </p>
    <IxRes>administration.portalFeatures.deleteSelectedFeature</IxRes>
    <p>{{ feature && feature.name }}</p>

    <template #confirm>
      <IxRes>administration.portalFeatures.buttons.confirmRemove</IxRes>
    </template>
    <template #cancel>
      <IxRes>administration.portalFeatures.buttons.cancelRemove</IxRes>
    </template>
  </ConfirmationModal>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

import ConfirmationModal from '@components/modals/ConfirmationModal'

export default {
  components: {
    ConfirmationModal
  },
  props: {
    value: Boolean,
    feature: Object
  },
  computed: {
    modelProxy
  }
}
</script>

<style lang="scss" scoped>
</style>
